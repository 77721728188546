<template>
  <div class="container__home">
    <div class="container__login">
      <h1>Bienvenue</h1>
      <div class="user">
        <form @submit.prevent="onSubmit" class="form">
          <label class="user__label" for="email">Adresse Mail</label>
          <input
            class="user__input"
            type="email"
            name="email"
            id="email"
            v-model="email"
          />
          <label class="user__label" for="password">Mot de passe</label>
          <input
            class="user__input"
            type="password"
            name="password"
            id="password"
            v-model="password"
          />
          <p class="user__err" v-if="err">{{ err.error.message }}</p>
          <button class=" button user__button" type="submit">Connection</button>
        </form>
        <router-link to="/register" class="user__register">
          <span>S'inscrire maintenant</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    err() {
      return this.$store.state.errorLogin;
    }
  },
  methods: {
    onSubmit() {
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password
      });
    }
  },
  beforeDestroy() {
    this.$store.commit("setErrorLogin", null);
  }
};
</script>

<style lang="scss">
h1 {
  font-size: 40px;
  color: #23233c;
  z-index: 10;
}
.form {
  display: inline-flex;
  flex-direction: column;
}

.button {
  margin-top: 20px;
  padding: 20px 20px;
  border: 1px solid red;
  cursor: pointer;
}

.container {
  &__home {
    background-image: url("../assets/background.png");
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
      content: " ";
      background-image: url("../assets/header_home.svg");
      background-size: contain;
      z-index: 0;
      height: 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      width: 100%;
      top: -10px;
    }

    &::after {
      content: " ";
      background-image: url("../assets/footer_home.svg");
      background-size: contain;
      z-index: 0;
      height: 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      width: 100%;
      bottom: -10px;
      background-position: bottom;
    }
  }

  &__login {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto;
    justify-content: center;
  }
}
.user {
  display: flex;
  flex-direction: column;
  margin: 40px;
  z-index: 1;

  &__input {
    padding: 20px;
    border: none;
    background-color: #fff;
    box-shadow: 0px 10px 10px rgba(13, 78, 129, 0.051);
    border-radius: 5px;
  }

  &__label {
    display: flex;
    flex-direction: revert;
    font-size: 16px;
    color: #3b5150;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 500;
  }
  &__button {
    color: #fff;
    padding: 15px 30px;
    background-color: #95cdcb;
    border: none;
    font-weight: 600;
    font-size: 15px;
    display: block;
    margin: 20px auto;
    border-radius: 18px;
    text-transform: uppercase;
  }
  &__register {
    color: #898888;
    z-index: 10;
  }
  &__err {
    margin-top: 20px;
    color: red;
  }
}

@media (min-width: 850px) {
  .container {
    &__home {
      background-image: url("../assets/background_desktop.svg");
      &::before {
        background-image: url("../assets/login/header_desktop.svg");
      }
      &::after {
        background-image: url("../assets/login/footer_desktop.svg");
      }
    }
  }
  .user {
    display: block;
  }
  .form {
    display: flex;
    width: 30%;
    margin: 0 auto;
  }
}
</style>
